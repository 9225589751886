import axios from 'axios';
import router from "@/router/index";
import store from "../store";

/**
 * zxp
 * 封装http的 get post 请求
 * @type {AxiosInstance}
 */

const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 1000 * 50 // 请求超时时间
});

request.interceptors.request.use(config => {
    // 如果 token 存在
    const token = store.getters["user/getToken"]();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
});

request.interceptors.response.use(response => {
    return response.data
}, error => {
    return Promise.reject(error)
});

export const get = (url, params) => {
    return new Promise((resolve, reject) => {
        request.get(url, {params}).then(res => {
            resolve(res);
        }).catch(error => {
            resolve({
                status: false,
                data: error,
                message: "系统错误"
            });
        })
    })
};

export const post = (url, data) => {
    return new Promise((resolve, reject) => {
        request.post(url, data).then(res => {
            resolve(res);
        }).catch(error => {
            resolve({
                status: false,
                data: error,
                message: "连接错误"
            });
        })
    })
};
