/**
 * 设置cookie
 * @param name 必填  cookie名称
 * @param value 必填 cookie的值
 * @param time 可惜 cookie存储时间（秒）
 */
export function setCookie(name, value, time) {
    if (time) {
        let day = new Date(new Date().getTime() + (time * 1000));
        document.cookie = `${name}=${value};expires=${day};path=/`;
    } else {
        document.cookie = `${name}=${value};path=/`;
    }
}

export function getCookie(name) {
    let arr = document.cookie.split('; ');

    for (let i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split('=');

        if (arr2[0] === name) {
            return arr2[1]
        }
    }
    return '';
}

export function removeCookie(name) {
    setCookie(name, '', 0)
}