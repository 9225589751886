import Storage from "../../utils/storage";

const {set, get} = new Storage({storage: "session", mark: "COURSE"});

export default {
    namespaced: true,
    state: {
        courseInfo: null, // 课程信息

    },
    getters: {
        getCourseInfo: state => () => {
            if (!state.courseInfo) {
                state.courseInfo = get('INFO');
            }
            return state.courseInfo
        },
    },
    mutations: {
        setCourseInfo(state, data) {
            state.courseInfo = data;
            set("INFO", data);
        },
    },
    action: {}
}