import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        name: "Page404",
        component: () => import("@/views/base/404.vue")
    },
    {
        path: "/guideGame",
        name: "GuideGame",
        component: () => import("@/views/game/GuideGame.vue")
    },
    {
        path: "/startGame",
        name: "StartGame",
        component: () => import("@/views/game/StartGame.vue")
    },
    {
        path: "/startGameList",
        name: "StartGame",
        component: () => import("@/views/game/StartGameList.vue")
    },
    {
        path: "/abilityDetails",
        name: "AbilityDetails",
        component: () => import("@/views/academic/AbilityDetails.vue")
    },
    {
        path: "/abilityAnalysis",
        name: "AbilityAnalysis",
        component: () => import("@/views/academic/AbilityAnalysis.vue")
    },
];
const router = new VueRouter({
    mode: 'hash',
    routes
});

export default router
