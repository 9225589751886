import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as http from "./requests"
Vue.prototype.$http = http;

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import "./config/mount";
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
