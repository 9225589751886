import Vue from 'vue';
import Vuex from 'vuex';
import user from "./modules/user";
import course from "./modules/course";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        course,
    },
    actions: {
        clear(state, type) {
            if (typeof type === "string"){
                for (let key in state.getters) {
                    let name = key.split("/")[0];
                    let value = key.split("/")[1];
                    if (name === type){
                        state.commit(name + "/" + value.replace("get", "set"), "");
                    }
                }
                return
            }
            for (let key in state.getters) {
                let name = key.split("/")[0];
                let value = key.split("/")[1];
                state.commit(name + "/" + value.replace("get", "set"), "");
            }
        }
    }
})
