export default class Storage {
    constructor(data) {
        this._storage = window.localStorage;
        this.mark = data.mark ? data.mark + "_" : "";
        if (data.storage === "session") {
            this._storage = window.sessionStorage;
        }
    }

    get(name) {
        if (!name) {
            return
        }
        const data = this._storage.getItem(this.mark + name);
        try {
            return JSON.parse(data);
        } catch (e) {
            return data
        }
    }

    set(name, value) {
        if (value === "" || typeof value === "undefined" || Object.prototype.toString.call(value) === "[object Null]") {
            this._storage.removeItem(this.mark + name);
            return
        }
        if (typeof value === "string" || typeof value === "number") {
            this._storage.setItem(this.mark + name, value);
            return
        }
        this._storage.setItem(this.mark + name, JSON.stringify(value));
    }
}