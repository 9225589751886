<template>
    <div class="my-loading">
        <div class="my-van-loading" v-if="loading">
            <van-loading :color="color" :type="type"
                         :size="size" :textSize="textSize" :textColor="textColor"
                         :vertical="vertical">
                {{ text }}
            </van-loading>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "myLoading",
    props: {
        loading: "",
        color: {
            type: String,
            default: "#1989fa"
        },
        type: "",
        text: "",
        size: "",
        textSize: "",
        textColor: "",
        vertical: {
            type: Boolean,
            default: true
        },
    }
}
</script>

<style scoped>
.my-loading {
    width: 100%;
    height: 100%;
    position: relative;
}

.my-van-loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
</style>