import {setCookie, getCookie, removeCookie} from "@/utils/cookie"
import Storage from "../../utils/storage";

const storage = new Storage({storage: "local", mark: "USER"});
const [get, set] = [storage.get.bind(storage), storage.set.bind(storage)]
export default {
    namespaced: true,
    state: {
        userName: '',
        userId: '',
        userMobile: '',
        token: '',
    },
    getters: {
        getUserName: state => () => {
            if (!state.userName) {
                state.userName = get('NAME');
            }
            return state.userName || {}
        },
        getUserId: state => () => {
            if (!state.userId) {
                state.userId = get('ID');
            }
            return state.userId
        },
        getUserMobile: state => () => {
            if (!state.userMobile) {
                state.userMobile = get('MOBILE');
            }
            return state.userMobile
        },
        getToken: state => () => {
            if (!state.token) {
                state.token = getCookie("token");
            }
            return state.token
        },
    },
    mutations: {
        setUserName(state, data) {
            state.userName = data;
            set("NAME", data);
        },
        setToken(state, token) {
            state.token = token;
            if (token) {
                setCookie("token", token);
            } else {
                removeCookie("token");
            }
        },
        setUserId(state, id) {
            state.userId = id;
            set("ID", id);
        },
        setUserMobile(state, data) {
            state.userMobile = data;
            set("MOBILE", data);
        },
    },
    actions: {}
}
