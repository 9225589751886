<template>
    <div id="app">
        <router-view name="header"></router-view>
        <transition :name="transitionName" mode="out-in">
            <router-view :key="viewKey" />
        </transition>
    </div>
</template>
<script>
    import $Bus from "./utils/eventBus"

    export default {
        name: "App",
        data() {
            return {
                transitionName: "",
                viewKey: Date.now()
            }
        },
        watch: {
            $route(to, from) {
                window.onresize = null;
                if (to.meta && (typeof to.meta.index === "number")) {
                    if (to.meta.index < from.meta.index) {
                        this.transitionName = "slide-left";
                    } else {
                        this.transitionName = "slide-right";
                    }
                } else {
                    this.transitionName = "";
                }
            }
        },
        mounted(){
            $Bus.$on("routerRefresh", () => {
                this.viewKey = Date.now();
            })
        },
        methods: {

        }
    }
</script>
<style>
    html, body {
        width: 100%;
        height: 100%;
    }

    h1, h2, h3, h4, h5, h6, p, span, i, div, ul, ol, li, em, iframe {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-style: normal;
    }

    input{
        outline:none;
    }

    #app {
        font-family: PingFang SC;
        font-style: normal;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
</style>
