<template>
    <div class="label-title">
        <slot name="icon"></slot>
        <!--<img v-if="!$slots.icon" src="@/assets/achievement/titleIcon.png" alt="">-->
        {{title}}
        <span class="sub-title">{{subTitle}}</span>
    </div>
</template>

<script>
    export default {
        name: "LabelTitle",
        props:{
            title: "",
            subTitle: ""
        }
    }
</script>

<style lang="less" scoped>
    .label-title{
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #151B37;
        img{
            height: 22px;
            vertical-align: text-bottom;
        }

        .sub-title{
            color: #949EA4;
            font-size: 12px;
            line-height: 20px;
            margin-left:8px;
        }
    }
</style>